import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, DefinitionList} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import hrTnbcSafetyTable1 from "../../../assets/TNBC-High-Risk-522-discontinue-safety-small-V1.svg"
import hrTnbcSafetyTable2 from "../../../assets/TNBC-High-Risk-522-interruption-safety-small-V2.svg"
import hrTnbcSafetyTable3 from "../../../assets/TNBC-High-Risk-522-safety-small-V7.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-522 - Adverse Reactions & Safety Data`,
    keywords: `keynote 522, adverse reactions`,
    description: `Health care professionals can review adverse reactions with KEYTRUDA® (pembrolizumab) in patients with high-risk early-stage triple-negative breast cancer (TNBC). See safety data from KEYNOTE-522.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/","@type":"MedicalWebPage","name":"Safety Data From KEYNOTE-522","description":"Safety included 778 patients who received at least 1 dose of KEYTRUDA in combination with neoadjuvant chemotherapy followed by KEYTRUDA as adjuvant treatment after surgery."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const tnbcSafetyAlt1 = "Most Common Adverse Reactions Resulting in Permanent Discontinuation of KEYTRUDA® (pembrolizumab) (≥1%) in KEYNOTE-522"
const tnbcSafetyAlt2 = "Most Common Adverse Reactions Leading to Interruption of KEYTRUDA® (pembrolizumab) (≥2%) in KEYNOTE-522"
const tnbcSafetyAlt3 = "Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA® (pembrolizumab) With Chemotherapy in KEYNOTE-522"

//from /src/util/data/indications.js
const indicationId = 27;
const jobCode = jobCodes[26].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced TNBC', url: '/safety/adverse-reactions/triple-negative-breast-cancer/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management ', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Selected Adverse Reactions in KEYNOTE&#8288;-&#8288;522'}
]

const tnbcArTableFootnotes = [
    {
        label: 'c.',
        text: 'Chemotherapy: carboplatin and paclitaxel followed by doxorubicin or epirubicin and cyclophosphamide.'
    },
    {
        label: 'd.',
        text: 'Graded per NCI CTCAE v4.0.'
    },
    {
        label: 'e.',
        text: 'Includes asthenia, fatigue.'
    },
    {
        label: 'f.',
        text: 'Includes dermatitis, dermatitis acneiform, dermatitis allergic, dermatitis bullous, dermatitis exfoliative generalized, drug eruption, eczema, incision site rash, injection site rash, rash, rash erythematous, rash follicular, rash macular, rash maculopapular, rash morbilliform, rash papular, rash pruritic, rash pustular, rash rubelliform, skin exfoliation, skin toxicity, toxic skin eruption, urticaria, vasculitic rash, viral rash.'
    },
    {
        label: 'g.',
        text: 'Includes neuropathy peripheral, peripheral motor neuropathy, peripheral sensorimotor neuropathy, peripheral sensory neuropathy.'
    },
    {
        label: 'h.',
        text: 'Includes aphthous ulcer, cheilitis, lip pain, lip ulceration, mouth ulceration, mucosal inflammation, oral mucosal eruption, oral pain, stomatitis, tongue blistering, tongue ulceration.'
    },
    {
        label: 'i.',
        text: 'Includes cough, productive cough, upper-airway cough syndrome.'
    },
    {
        label: 'j.',
        text: 'Includes abdominal discomfort, abdominal pain, abdominal pain lower, abdominal pain upper, abdominal tenderness.'
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId}  jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>
                        
                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>Safety included 778 patients who received at least 1 dose of KEYTRUDA in combination with neoadjuvant chemotherapy followed by KEYTRUDA as adjuvant treatment after surgery.</p>
                                <p>The median duration of exposure to KEYTRUDA 200 mg every 3 weeks was 13.3 months (range: 1 day–21.9 months).</p>
                                <p><b>Fatal adverse reactions occurred in 0.9% of patients receiving KEYTRUDA, including 1 each of:</b></p>
                                <ul>
                                    <li>adrenal crisis</li>
                                    <li>autoimmune encephalitis</li>
                                    <li>hepatitis</li>
                                    <li>pneumonia</li>
                                    <li>pneumonitis</li>
                                    <li>pulmonary embolism</li>
                                    <li>sepsis in association with multiple organ dysfunction syndrome and myocardial infarction</li>
                                </ul>
                                <p><b>Serious adverse reactions occurred in 44% of patients receiving KEYTRUDA. Serious adverse reactions in ≥2% of patients who received KEYTRUDA included:</b></p>
                                <ul>
                                    <li>febrile neutropenia (15%)</li>
                                    <li>pyrexia (3.7%)</li>
                                    <li>anemia (2.6%)</li>
                                    <li>neutropenia (2.2%)</li>
                                </ul>
                            </TextBlock>

                            {/* Keynote-specific AR Table 1 of 3 */}
                            <DataTable 
                                title="KEYTRUDA was discontinued for adverse reactions in 20% of patients. The most common adverse reactions (≥1%) resulting in permanent discontinuation of KEYTRUDA were:" 
                                mobileImage={() => <img src={hrTnbcSafetyTable1} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt1}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-High-Risk-522-discontinue-safety-large-V1.png" alt={tnbcSafetyAlt1}/>}
                            />

                            {/* Keynote-specific AR Table 2 of 3 */}
                            <DataTable 
                                title="Adverse reactions leading to the interruption of KEYTRUDA occurred in 57% of patients. The most common adverse reactions leading to interruption of KEYTRUDA (≥2%) were:" 
                                mobileImage={() => <img src={hrTnbcSafetyTable2} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt2}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-High-Risk-522-interruption-safety-large-V2.png" alt={tnbcSafetyAlt2}/>}
                            />

                            <TextBlock>
                                <p>For patients with TNBC treated with KEYTRUDA in the neoadjuvant setting, monitor blood cortisol at baseline, prior to surgery, and as clinically indicated. </p>
                            </TextBlock>

                            {/* Keynote-specific AR Table 3 of 3 */}
                            <DataTable 
                                title="Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA" 
                                mobileImage={() => <img src={hrTnbcSafetyTable3} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt3}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-High-Risk-522-safety-large-V7.png" alt={tnbcSafetyAlt3}/>}
                                footnotes={tnbcArTableFootnotes}
                                definitions="ALT = alanine aminotransferase; AST = aspartate aminotransferase; NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events."
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
